import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Байгууллагын ерөнхий мэдээлэл харах, засах",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-28T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 5
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Байгууллагын ерөнхий мэдээлэл харах, засах`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Дэлгэцийн баруун дээд булан дахь хэрэглэгчийн нэрийг дарж хэрэглэгчийн тохиргоо хэсэгт очно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Organization]`}</strong>{` - хэсэгт дарж хэрэглэгч өөрийн бүртгэлтэй байгууллагын ерөнхий мэдээллийг харах, засах боломжтой. Энэ хэсэгт дараах мэдээлэл багтана:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Байгууллагын нэр`}</li>
          <li parentName="ul">{`Хандах эрхийн төвшин`}</li>
          <li parentName="ul">{`Шууд оролцогчийн бүртгэлийн дугаар`}</li>
          <li parentName="ul">{`Утас`}</li>
          <li parentName="ul">{`Хаяг`}</li>
          <li parentName="ul">{`Цахим хуудас`}</li>
          <li parentName="ul">{`Хэрэглэгчийн төлөв`}</li>
          <li parentName="ul">{`Бүртгүүлсэн огноо`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Members]`}</strong>{` Гишүүд - Тухайн байгууллагын харьяа нэвтрэх эрх бүхий бүртгэлтэй хэрэглэгчдийн мэдээлэл`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Clients]`}</strong>{` Харилцагчид - Байгууллагын харилцагчдын бүртгэл хэсэгт `}<strong parentName="p">{`[Add Client]`}</strong>{` товч дарж шинэ харилцагчийн мэдээлэл оруулна. Харилцагчийн мэдээллийг `}<strong parentName="p">{`[Edit]`}</strong>{` дарж засварлаж, `}<strong parentName="p">{`[Delete]`}</strong>{` дарж устгах боломжтой.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      